import * as React from "react"
import '../styles/global.css';
import MainPage from './as';
import { Helmet } from 'react-helmet';

// markup
export default function IndexPage() {
    return (
        <React.Fragment>

            <Helmet>
                <title>AmericaSober CRM</title>
                <link rel="shortcut icon" type="image/png" href="/src/images/favicon.ico" />
            </Helmet>

            <MainPage />

        </React.Fragment>
    )
}
